import React from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { PDFViewer, Page, Font, Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import imgBorder from './img/orarng_cert_border.png';
import imgLogo from './img/orarng_logo_wm.png';
import imgSig from './img/losson_sig.jpg';
import styled from 'styled-components';

interface CompletionCertificateProps {
    studentName: string;
    courseName: string;
    completionDate: string;
    onClose: () => void;
    open: boolean;
}

const pageMargin = 36;

const styles = StyleSheet.create({
    pageBackground: {
        position: 'absolute',
        height: 612 - (pageMargin * 2),
        width: 792 - (pageMargin * 2),
        top: pageMargin,
        left: pageMargin
        // minWidth: '100%',
        // minHeight: '100%',

        // height: '100%',
        // width: '100%',

    },
    logoBackground: {
        position: 'absolute',
        // minWidth: '100%',
        // minHeight: '100%',
        // display: 'block',

        // left: "50%",
        // right: "-50%",
        height: '380px',
        width: '380px',
        left: '50%',
        top: '55%',
        marginLeft: -190,
        marginTop: -190
    },
    header: {
        fontFamily: 'Quintessential',
        fontSize: 48,
        textAlign: 'center',
        position: 'absolute',
        color: "#000000"
    },
    headerShadowOne: {
        fontFamily: 'Quintessential',
        fontSize: 48,
        textAlign: 'center',
        position: 'absolute',
        color: "#FFFFFF",
        left: 2,
        top: 1,
    },
    headerShadowTwo: {
        fontFamily: 'Quintessential',
        fontSize: 48,
        textAlign: 'center',
        position: 'absolute',
        color: "#999999",
        left: 2.6,
        top: 3.2,
    },
    certBodyText: {
        fontFamily: 'Quintessential',
        fontSize: 36,
        textAlign: 'center',
        marginHorizontal: pageMargin + 50
        //marginVertical: 8
    },
    certBodyTextSmall: {
        fontFamily: 'Quintessential',
        fontSize: 26,
        textAlign: 'center',
        marginHorizontal: pageMargin + 50
        // marginVertical: 4
    },
    sigLine: {
        fontSize: 14,
        borderTop: 1,
        borderTopColor: "black",
        borderTopStyle: "solid",
        paddingHorizontal: "40px",
        paddingTop: "5px",
        position: "absolute",
        bottom: pageMargin + 25,
        right: pageMargin + 60
    },
    sig: {
        position: "absolute",
        width: 142,
        height: 36,
        bottom: pageMargin + 35,
        right: pageMargin + 110
    },
    page: {

    }
});

const StyledPDFViewer = styled(PDFViewer)`
    position: fixed;
    top: 64px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 64px);
    margin: 0;
    padding: 0;   
`;

export default function CompletionCertificate(props: CompletionCertificateProps) {

    Font.register({ family: 'Quintessential', src: 'https://fonts.gstatic.com/s/quintessential/v8/fdNn9sOGq31Yjnh3qWU14DdtjY5z.woff' });

    //disable word hyphenation
    Font.registerHyphenationCallback((words) => [words]);
    // LETTER: [612, 792] (72 per inch)

    return (

        <Dialog onClose={props.onClose} open={props.open} fullScreen>
            <AppBar style={{ position: "relative" }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">{`Course Completion Certificate for ${props.courseName}`}</Typography>
                </Toolbar>
            </AppBar>
            <StyledPDFViewer>
                <Document>
                    <Page size="LETTER" orientation="landscape" style={styles.page}>
                        <Image src={imgBorder} style={styles.pageBackground} />
                        <Image src={imgLogo} style={styles.logoBackground} />
                        <Image src={imgSig} style={styles.sig} />
                        <Text style={styles.sigLine}>Jennifer Losson, AGI-ENV</Text>
                        <View style={{ marginTop: 20 + pageMargin }}>
                            <Text style={styles.headerShadowTwo}>Oregon Army National Guard</Text>
                            <Text style={styles.headerShadowOne}>Oregon Army National Guard</Text>
                            <Text style={styles.header}>Oregon Army National Guard</Text>
                            <View style={{ marginTop: 20 + pageMargin + 45 }}>
                                <Text style={styles.certBodyText}>{props.studentName}</Text>
                                <Text style={styles.certBodyTextSmall}>has successfully completed the</Text>
                                <Text style={styles.certBodyText}>{props.courseName.trim()}</Text>
                                <Text style={styles.certBodyTextSmall}>On</Text>
                                <Text style={styles.certBodyText}>{props.completionDate}</Text>
                            </View>


                        </View>
                    </Page>
                </Document>
            </StyledPDFViewer>
        </Dialog>
    );
}



